var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"program"}},[_c('loader',{attrs:{"object":"#ffffff","color1":"#ffffff","color2":"#ffffff","time":"100","size":"5","speed":"2","bg":"#5e151d","objectbg":"#999793","opacity":"100","name":_vm.selected,"disableScrolling":"false"}}),_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.desserts.length == 0),expression:"desserts.length == 0"}]},[_c('b-jumbotron',{staticStyle:{"background-image":"url(https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/ImagenDashboard.jpg?alt=media)","background-position":"center center","background-repeat":"no-repeat","background-size":"cover"},attrs:{"text-variant":"white"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-badge',{attrs:{"variant":""}},[_vm._v("UCPS ")])]},proxy:true},{key:"lead",fn:function(){return [_c('h4',[_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("El proceso de matrícula ha finalizado.")])],1)]},proxy:true}])})],1),_vm._l((_vm.desserts),function(item,j){return _c('div',{key:j,staticClass:"card mb-3"},[_c('div',{staticClass:"card-header"},[_c('h1',[_c('b-icon',{attrs:{"icon":"blockquote-left"}}),_vm._v(_vm._s(item.name))],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header",staticStyle:{"background-color":"rgb(119, 35, 45)"}},[_c('h3',[_c('b-icon',{attrs:{"icon":"tools"}}),_vm._v(" Área Tecnica")],1)]),_c('div',{staticClass:"card-body"},[_c('vue-glide',{attrs:{"perView":_vm.count}},[_vm._l((item.list[1].programs),function(pro,k){return _c('vue-glide-slide',{key:k},[_c('b-card',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(pro.name),expression:"pro.name",modifiers:{"hover":true,"top":true}}],attrs:{"title":'(' +
                    (k + 1) +
                    '/' +
                    item.list[1].programs.length +
                    ') ' +
                    pro.name,"img-src":'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/' +
                    pro.image +
                    '?alt=media',"img-alt":"Imagen","img-top":"","tag":"article"}},[(pro.discount == true)?_c('div',{staticClass:"row"},[_c('b',{staticClass:"col line text-secondary"},[_vm._v("S/"+_vm._s(pro.pay_enrollment))]),_c('h5',{staticClass:"col text-primary"},[_c('b',[_vm._v("Matrícula S/"+_vm._s(pro.pay_enrollment))])]),_c('h5',{staticClass:"col text-primary"},[_c('b',[_vm._v("Pensión S/"+_vm._s(pro.pay_pension))])])]):_c('div',[_c('h5',{staticClass:"text-primary"},[_c('b',[_vm._v("Matrícula S/"+_vm._s(pro.pay_enrollment))])]),_c('h5',[_c('b',[_vm._v("Pensión S/"+_vm._s(pro.pay_pension))])])]),_c('center',{staticClass:"mt-3"},[_c('b-button',{staticStyle:{"border":"none","background-color":"#1d425e"},attrs:{"to":'/requisition/' + pro.requisitionId + '/ '}},[_vm._v("Matricularse")])],1)],1)],1)}),_c('template',{slot:"control"},[_c('b-avatar',{staticClass:"border",attrs:{"button":"","variant":"light","data-glide-dir":"<"}},[_c('b-icon-caret-left-fill')],1),_c('b-avatar',{staticClass:"border",attrs:{"button":"","variant":"light","data-glide-dir":">"}},[_c('b-icon-caret-right-fill')],1)],1)],2)],1)]),_c('div',{staticClass:"card mt-3"},[_c('div',{staticClass:"card-header",staticStyle:{"background-color":"rgb(119, 35, 45)"}},[_c('h3',[_c('b-icon',{attrs:{"icon":"journal-bookmark"}}),_vm._v(" Área Administrativa")],1)]),_c('div',{staticClass:"card-body"},[_c('vue-glide',{attrs:{"perView":_vm.count}},[_vm._l((item.list[0].programs),function(pro,k){return _c('vue-glide-slide',{key:k},[_c('b-card',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(pro.name),expression:"pro.name",modifiers:{"hover":true,"top":true}}],attrs:{"title":'(' +
                    (k + 1) +
                    '/' +
                    item.list[0].programs.length +
                    ') ' +
                    pro.name,"img-src":'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/' +
                    pro.image +
                    '?alt=media',"img-alt":"Imagen","img-top":"","tag":"article"}},[(pro.discount == true)?_c('div',{staticClass:"row"},[_c('b',{staticClass:"col line text-secondary"},[_vm._v("S/"+_vm._s(pro.pay_enrollment))]),_c('h5',{staticClass:"col text-primary"},[_c('b',[_vm._v("Matrícula S/"+_vm._s(pro.pay_enrollment))])]),_c('h5',{staticClass:"col text-primary"},[_c('b',[_vm._v("Pensión S/"+_vm._s(pro.pay_pension))])])]):_c('div',[_c('h5',{staticClass:"text-primary"},[_c('b',[_vm._v("Matrícula S/"+_vm._s(pro.pay_enrollment))])]),_c('h5',[_c('b',[_vm._v("Pensión S/"+_vm._s(pro.pay_pension))])])]),_c('center',{staticClass:"mt-3"},[_c('b-button',{staticStyle:{"border":"none","background-color":"#1d425e"},attrs:{"to":'/requisition/' + pro.requisitionId + '/ '}},[_vm._v("Matricularse")])],1)],1)],1)}),_c('template',{slot:"control"},[_c('b-avatar',{staticClass:"border",attrs:{"button":"","variant":"light","data-glide-dir":"<"}},[_c('b-icon-caret-left-fill')],1),_c('b-avatar',{staticClass:"border",attrs:{"button":"","variant":"light","data-glide-dir":">"}},[_c('b-icon-caret-right-fill')],1)],1)],2)],1)])])])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }