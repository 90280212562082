<template>
  <div id="program">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div v-show="desserts.length == 0">
        <b-jumbotron
          style="
            background-image: url(https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/ImagenDashboard.jpg?alt=media);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          "
          text-variant="white"
        >
          <template #header><b-badge variant="">UCPS </b-badge></template>
          <template #lead>
            <h4>
              <b-badge variant="danger"
                >El proceso de matrícula ha finalizado.</b-badge
              >
            </h4>
          </template>
        </b-jumbotron>
      </div>
      <!--aca-->
      <div class="card mb-3" v-for="(item, j) in desserts" :key="j">
        <div class="card-header">
          <h1><b-icon icon="blockquote-left" />{{ item.name }}</h1>
        </div>
        <div class="card-body">
          <!-- tecnica -->
          <div class="card">
            <div class="card-header" style="background-color: rgb(119, 35, 45)">
              <h3><b-icon icon="tools" /> Área Tecnica</h3>
            </div>
            <div class="card-body">
              <vue-glide :perView="count">
                <vue-glide-slide
                  v-for="(pro, k) in item.list[1].programs"
                  :key="k"
                >
                  <!---->
                  <b-card
                    :title="
                      '(' +
                      (k + 1) +
                      '/' +
                      item.list[1].programs.length +
                      ') ' +
                      pro.name
                    "
                    :img-src="
                      'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/' +
                      pro.image +
                      '?alt=media'
                    "
                    img-alt="Imagen"
                    img-top
                    tag="article"
                    v-b-popover.hover.top="pro.name"
                  >
                    <div class="row" v-if="pro.discount == true">
                      <b class="col line text-secondary"
                        >S/{{ pro.pay_enrollment }}</b
                      >
                      <h5 class="col text-primary">
                        <b>Matrícula S/{{ pro.pay_enrollment }}</b>
                      </h5>
                      <h5 class="col text-primary">
                        <b>Pensión S/{{ pro.pay_pension }}</b>
                      </h5>
                    </div>
                    <div v-else>
                      <h5 class="text-primary">
                        <b>Matrícula S/{{ pro.pay_enrollment }}</b>
                      </h5>
                      <h5>
                        <b>Pensión S/{{ pro.pay_pension }}</b>
                      </h5>
                    </div>

                    <center class="mt-3">
                      <b-button
                        :to="'/requisition/' + pro.requisitionId + '/ '"
                        style="border: none; background-color: #1d425e"
                        >Matricularse</b-button
                      >
                    </center>
                  </b-card>
                </vue-glide-slide>
                <template slot="control">
                  <b-avatar
                    class="border"
                    button
                    variant="light"
                    data-glide-dir="<"
                    ><b-icon-caret-left-fill
                  /></b-avatar>
                  <b-avatar
                    class="border"
                    button
                    variant="light"
                    data-glide-dir=">"
                    ><b-icon-caret-right-fill
                  /></b-avatar>
                </template>
              </vue-glide>
            </div>
          </div>
          <!--administrativa-->
          <div class="card mt-3">
            <div class="card-header" style="background-color: rgb(119, 35, 45)">
              <h3><b-icon icon="journal-bookmark" /> Área Administrativa</h3>
            </div>
            <div class="card-body">
              <vue-glide :perView="count">
                <vue-glide-slide
                  v-for="(pro, k) in item.list[0].programs"
                  :key="k"
                >
                  <!---->
                  <b-card
                    :title="
                      '(' +
                      (k + 1) +
                      '/' +
                      item.list[0].programs.length +
                      ') ' +
                      pro.name
                    "
                    :img-src="
                      'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/' +
                      pro.image +
                      '?alt=media'
                    "
                    img-alt="Imagen"
                    img-top
                    tag="article"
                    v-b-popover.hover.top="pro.name"
                  >
                    <div class="row" v-if="pro.discount == true">
                      <b class="col line text-secondary"
                        >S/{{ pro.pay_enrollment }}</b
                      >
                      <h5 class="col text-primary">
                        <b>Matrícula S/{{ pro.pay_enrollment }}</b>
                      </h5>
                      <h5 class="col text-primary">
                        <b>Pensión S/{{ pro.pay_pension }}</b>
                      </h5>
                    </div>
                    <div v-else>
                      <h5 class="text-primary">
                        <b>Matrícula S/{{ pro.pay_enrollment }}</b>
                      </h5>
                      <h5>
                        <b>Pensión S/{{ pro.pay_pension }}</b>
                      </h5>
                    </div>

                    <center class="mt-3">
                      <b-button
                        :to="'/requisition/' + pro.requisitionId + '/ '"
                        style="border: none; background-color: #1d425e"
                        >Matricularse</b-button
                      >
                    </center>
                  </b-card>
                </vue-glide-slide>
                <template slot="control">
                  <b-avatar
                    class="border"
                    button
                    variant="light"
                    data-glide-dir="<"
                    ><b-icon-caret-left-fill
                  /></b-avatar>
                  <b-avatar
                    class="border"
                    button
                    variant="light"
                    data-glide-dir=">"
                    ><b-icon-caret-right-fill
                  /></b-avatar>
                </template>
              </vue-glide>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Glide, GlideSlide } from "vue-glide-js";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  data() {
    return {
      count: 3,
      indexTab: 0,
      selected: "",
      value: true,
      desserts: [],
    };
  },
  created() {
    this.initialize();
    if (window.innerWidth < 1000) {
      this.count = 1;
    } else {
      this.count = 3;
    }
  },
  methods: {
    lista(dato) {
      if (dato.length != 0) return true;
      else return false;
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/enrollment", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            aux.push(res.data[i]);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.card-body {
  padding: 3% 3%;
}
.card-header {
  background-color: #1d425e;
  color: white;
}
.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card-title {
  height: 70px;
}
</style>

<style scoped>
.card-header {
  background-color: #1d425e;
  color: white;
}
.nav-tabs .nav-link.active {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  color: black;
  font-weight: bolder;
}
.nav-tabs .nav-link:hover {
  color: white;
}
button[data-glide-dir="<"] {
  position: absolute;
  border: 0;
  outline: 0;
  padding: 10px;
  top: 30%;
}
button[data-glide-dir="<"] {
  left: 5px;
}
button[data-glide-dir=">"] {
  position: absolute;
  border: 0;
  outline: 0;
  padding: 10px;
  top: 30%;
}
button[data-glide-dir=">"] {
  right: 5px;
}
.line {
  text-decoration-line: line-through;
}
.glide__slide {
  width: none;
}
</style>